import { API } from "aws-amplify";
import Cookies from "js-cookie";

function getPrincipalDashboard(payload) {
  const token = Cookies.get('tokenId')

  return API.graphql({
    query: `query MyQuery {
        getPrincipalsDashboard(schoolId: "${payload.payload.schoolId}")
      }`,
    authToken: token
  })
}

function getTeacherDashboard(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
      getTeacherDashboard(
      schoolId: "${payload?.payload?.schoolId}", 
      class: "${payload?.payload?.Class}",
      section: "${payload?.payload?.section}")
    }`,
    authToken: token
  })
}

function getSuperAdminDashboard(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
      countItems {
        SADashClinicCard {
          name
          phone
          nurse
          pincode
          specialty
          staff
          status
          type
          updatedAt
          ameneties
          city
          clinicCode
          createdAt
          doctor
          email
          establishment
          state
          address
        }
        SADashEventCard {
          updatedAt
          toDate
          strength
          status
          schoolId
          location
          isActive
          fromDate
          eventId
          createdAt
          clinic
          acManager {
            clinicId
            family_name
            email
            given_name
            gender
            groups
            schoolId
            userId
          }
          doctor {
            email
            clinicId
            family_name
            gender
            given_name
            groups
            userId
            schoolId
          }
          incharge {
            userId
            schoolId
            groups
            given_name
            gender
            family_name
            email
            clinicId
          }
          opsManager {
            clinicId
            email
            family_name
            gender
            given_name
            groups
            schoolId
            userId
          }
        }
        SADashScreeningCard {
          createdAt
          eventId
          mappingId
          firstName
          screeningDate
        }
        deviceData {
          WelchAllyn
          Seca
          Massimo
          Higo
          Amplivox
        }
        userCount
        totalDeviceCount
        studentCount
        screeningCount
        schoolCount
        eventCount
        clinicCount
        behavioralScreeningCount
        behavioralValidated
        physicalValidated
        physicalScreeningCount
        SADashSchoolCard {
          staffAndAmenities {
            teachers
            infirmary {
              doctor
              nurse
            }
            counsellors {
              counsellorsCount
            }
          }
          strength
          schoolName
          schoolId
          schoolCode
          annualOrientationMonth
          screeningPlan
          screeningFrom
          screeningTo
          updatedAt
          address
          city
          board
        }
      }
    }`,
    authToken: token
  })
}

function getSchoolAdminDashboard(payload) {

  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
      getSchoolAdminDashboard(schoolId: "${payload.payload}") {
        behavioralScreeningCount
        behavioralValidated
        physicalScreeningCount
        physicalValidated
        schoolEvent {
          eventId
          fromDate
          toDate
        }
        schoolProfile {
          schoolName
          screeningFrom
          screeningTo
          schoolId
          schoolCode
          strength
        }
        schoolStudent
        schoolTeacher
        studentsScreened
      }
    }`,
    authToken: token
  })
}

function getSuperAdminLiveScreeningData(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `mutation MyMutation {
    screeningLiveData {
      DayOfScreening
      schoolId
      schoolName
      strength
      todayEvent
      screened
      total
    }
  }`,
    authToken: token
  })
}

function getSuperAdminLiveSchoolData(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `mutation MyMutation {
    schoolLiveData (schoolId: "${payload.schoolId}") {
      validationPending
      validated
      reexam
      absent
    }
  }`,
    authToken: token
  })
}

function getMissingScreeningData(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `mutation MyMutation {
    missingLiveData (schoolId: "${payload.schoolId}") {
      screeningId
      studentId
      studentName
      class
      status
      data {
        leftEar
        rightEar
        throat
        heart
        lungs
        eye
        height
        weight
        bloodPressure
        heartRate
        hemoglobin
        spo2
      }
    }
  }`,
    authToken: token
  })
}

function getPrincipalLiveScreeningData(payload) {

  const token = Cookies.get('tokenId')
  return API.graphql({
    query: ``,
    authToken: token
  })
}

function getSperadminLiveDoctorValidation(payload) {

  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `query MyQuery {
    doctorValidationLiveData {
      doctorId
      doctorName
      validated
      validationDueDate
      totalAssigned
      typeOfScreening
    }
  }
  `,
    authToken: token
  })
}

function updateDashboardLiveData(payload) {
  const token = Cookies.get('tokenId')
  return API.graphql({
    query: `mutation MyMutation {
      markScreeningTrue(
        eventId: "${payload.payload.eventId}", 
        screeningId: "${payload.payload.screeningId}", 
        mapId: "${payload.payload.mapId}"
        )
    }
  `,
    authToken: token
  })
}

const dashboards = {
  getPrincipalDashboard,
  getTeacherDashboard,
  getSchoolAdminDashboard,
  getSuperAdminDashboard,
  getSuperAdminLiveScreeningData,
  getSuperAdminLiveSchoolData,
  getPrincipalLiveScreeningData,
  getSperadminLiveDoctorValidation,
  updateDashboardLiveData,
  getMissingScreeningData
}

export default dashboards;