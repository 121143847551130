import React, { useEffect, useState } from "react";
import schoolReducer from "../../redux/reducers/school.reducer";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import BlankDataScreen from "../BlankDataScreen/BlankDataScreen";
import analytics from "../../graphqlApiServices/analytics";
import { PieChart } from "@mui/x-charts/PieChart";

function SkidsAnalytics() {
  const location = useLocation();
  const dispatch = useDispatch();

  const getSchools = schoolReducer.actions.getSchoolsRequest;
  const { getAnalyticsData } = analytics;
  const schools = useSelector((state) => state.schoolReducer.data);
  const data = useSelector((state) => state.analyticsReducer.data);

  const [selectedSchools, setSelectedSchools] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [issueData, setIssueData] = useState();
  const [chartData, setChartData] = useState();
  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(false);

  const issues = [
    { name: "Abdomen", value: "abdomenAssessment" },
    { name: "Cough", value: "coughAssessment" },
    { name: "Dental", value: "dentalAssessment" },
    { name: "Ear", value: "earAssessment" },
    { name: "Eye", value: "eyeAssessment" },
    { name: "Hair", value: "hairAssessment" },
    { name: "Hearing", value: "hearingAssessment" },
    { name: "Heart", value: "heartAssessment" },
    { name: "Lungs", value: "lungsAssessment" },
    { name: "Skin", value: "skinAssessment" },
    { name: "Throat", value: "throatAssessment" },
  ];

  const issueKeys = {
    abdomenAssessment: "Abdomen",
    coughAssessment: "Cough",
    dentalAssessment: "Dental",
    earAssessment: "Ear",
    eyeAssessment: "Eye",
    hairAssessment: "Hair",
    hearingAssessment: "Hearing",
    heartAssessment: "Heart",
    lungsAssessment: "Lungs",
    skinAssessment: "Skin",
    throatAssessment: "Throat",
  };

  useEffect(() => {
    if (location.pathname.includes("superadmin")) {
      dispatch(getSchools());
    }
  }, []);

  useEffect(() => {
    if (issueData && Object.keys(issueData).length > 0) {
      // Prepare chart data for each issue dynamically\
      const preparedData = {};
      selectedIssues.forEach((issueKey) => {
        preparedData[issueKey] = Object.values(issueData).map(
          (school) => ({
            label: school.schoolName,
            value: school.issues[issueKey] || 0, // Use 0 if the issue doesn't exist
          })
        );
      });
      setChartData(preparedData);
    }
    setLoading(false);
  }, [issueData, selectedIssues]);

  const getAnalytics = () => {
    if (selectedSchools.length > 0 && selectedIssues.length > 0) {
      setLoading(true);
      getAnalyticsData({
        schoolId: selectedSchools,
        issues: selectedIssues,
      })
        .then((res) => {
          console.log(res, "res");
          console.log(
            JSON.parse(res?.data?.getAnalytics),
            "JSON.parse(res?.data)"
          );
          setIssueData(JSON.parse(res?.data?.getAnalytics));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (selectedIssues.length > 0) {
      const q = [];
      for (let i = 0; i < data?.students?.length; i++) {
        if (
          data?.students[i]?.issues?.some((item) =>
            selectedIssues.includes(item)
          )
        ) {
          q.push(data?.students[i]);
        }
      }
      setDisplayData(q);
    } else {
      setDisplayData(data?.students);
    }
  }, [data, selectedIssues]);

  const renderValue = (selected) => {
    const x = issues?.filter((el) => selectedIssues?.includes(el.value));
    const y = x.map((z) => z.name);
    return y.join(", ");
  };

  const onChange = (e, p) => {
    const x = e.target.value;
    const y = p.props.value;
    setIssueData();
    setChartData();
    setSelectedIssues(x);
  };
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "90vh",
        alignItems: "center",
        flexDirection: "column",
        width: "70%",
        zIndex: 999,
        position: "absolute",
        backgroundColor: "#FAF8F7",
      }}
    >
      <CircularProgress size={50} />
      <div style={{ marginTop: 40 }}>Generating Results...</div>
    </div>
  ) : (
    <div>
      <div>Skids Analytics</div>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          gap: 10,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: 10 }}>
          <FormControl>
            {/* <InputLabel id="demo-simple-select-label">School</InputLabel> */}
            <Autocomplete
              size="small"
              options={schools}
              value={selectedSchools.map((id) =>
                schools.find((school) => school.schoolId === id)
              )}
              disableCloseOnSelect
              sx={{ width: 250 }}
              multiple={true}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label={"School"}
                  InputLabelProps={{ style: { fontSize: 15 } }}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.schoolName}
                </li>
              )}
              getOptionLabel={(x) => {
                return x?.schoolName ?? "";
              }}
              onChange={(e, value) => {
                const selectedIds = value.map((school) => school.schoolId); // Extract schoolId
                console.log(selectedIds);
                setSelectedSchools(selectedIds); // Save only schoolIds in state
              }}
            />
          </FormControl>
          <FormControl>
            <InputLabel id="demo-simple-select-label" size="small">
              Issue(s)
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedIssues}
              label="Issue(s)"
              onChange={onChange}
              size="small"
              disabled={selectedSchools?.length === 0}
              sx={{ width: 250 }}
              multiple
              renderValue={renderValue}
            >
              {issues?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    <Checkbox checked={selectedIssues?.includes(item.value)} />
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <button
            style={{
              cursor: "pointer",
              padding: 10,
              borderRadius: 10,
              backgroundColor: "#1740A1",
              color: "#fff",
              fontWeight: 600,
              border: "none",
              height: "fit-content",
              alignItems: "center",
            }}
            onClick={getAnalytics}
          >
            Get Result
          </button>
        </div>
      </div>
      {chartData &&
        selectedIssues.length > 0 &&
        selectedSchools.length > 0 &&
        !loading ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {selectedIssues.map((issueKey) => {
            const issueData = chartData[issueKey];
            const hasData =
              issueData && issueData.some((dataItem) => dataItem.value > 0);
            return (
              <div
                key={issueKey}
                style={{ margin: "20px auto", maxWidth: "50%" }}
              >
                <h3>{`${issueKeys[issueKey]} Issues`}</h3>
                {hasData ? (
                  <PieChart
                    margin={{ top: 10, bottom: 100 }}
                    series={[
                      {
                        data: chartData[issueKey] || [],
                        outerRadius: "80%",
                        innerRadius: "40%",
                      },
                    ]}
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: { vertical: "bottom", horizontal: "left" },
                        itemMarkWidth: 36,
                        itemMarkHeight: 11,
                        markGap: 9,
                        itemGap: 14,
                        padding: 13,
                      },
                    }}
                    width={400}
                    height={400}
                  />
                ) : (
                  <div style={{ padding: "50px 0", marginTop: 20 }}>
                    <p>No data available for this issue.</p>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div style={{ marginTop: 30 }}>
          <BlankDataScreen
            title="Oops! No data available"
            description="Please select the above filters or try changing them."
            hideButton={true}
          />
        </div>
      )}
    </div>
  );
}

export default SkidsAnalytics;
