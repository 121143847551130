import React, { useState, useEffect, useRef, Children } from "react";
import classes from "./DashboardLayout.module.css";
import dashboard from "../../../assets/images/dashboard.svg";
import dashboardWhite from "../../../assets/images/dashboard-white.svg";
import events from "../../../assets/images/events.svg";
import eventsWhite from "../../../assets/images/events-white.svg";
import students from "../../../assets/images/students.svg";
import studentsWhite from "../../../assets/images/students-white.svg";
import performance from "../../../assets/images/performance.svg";
import performanceWhite from "../../../assets/images/performance-white.svg";
import schoollogo from "../../../assets/images/schoollogo.png";
import support from "../../../assets/images/support.svg";
import notification from "../../../assets/images/notification.svg";
import triangle from "../../../assets/images/triangle.svg";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";
import bitmap from "../../../assets/images/bitmap.png";
import rectangle from "../../../assets/images/rectangle.png";
import infirmary from "../../../assets/images/infirmary.svg";
import infirmaryWhite from "../../../assets/images/infirmaryWhite.svg";
import calender from "../../../assets/images/calender.svg";
import calenderWhite from "../../../assets/images/Calender-white.svg";
import initial from "../../../assets/images/initial.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { blue } from "@mui/material/colors";
import questionmark from "../../../assets/images/questionmark.svg";
import clinic from "../../../assets/images/clinic.svg";
import doctor from "../../../assets/images/doctor.svg";
import clinicWhite from "../../../assets/images/clinicWhite.svg";
import doctorWhite from "../../../assets/images/doctorWhite.svg";
import school from "../../../assets/images/school.svg";
import schoolWhite from "../../../assets/images/schoolWhite.svg";
import patient from "../../../assets/images/patient.svg";
import patientWhite from "../../../assets/images/patientWhite.svg";
import device from "../../../assets/images/device.svg";
import deviceWhite from "../../../assets/images/deviceWhite.svg";
import calWhite from '../../../assets/images/calWhite.svg'
import line from "../../../assets/images/line.svg";
import Student from "../StudentTable/Student";
import Cookies from "js-cookie";
import Logo from '../../../assets/images/SkidsSuperManLogo.svg';
import GridViewIcon from '@mui/icons-material/GridView';
import { ChevronLeft } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import settings from '../../../assets/images/settings.svg'
import settingswhite from '../../../assets/images/settingswhite.svg'
import needhelp from '../../../assets/images/needhelp.svg'
import helpsupport from '../../../assets/images/helpsupport.svg'
import stethoscope from '../../../assets/images/stethoscopeWhite.svg'
import analytics from '../../../assets/images/analytics.svg'
import reportanalytics from '../../../assets/images/report.svg'
import dailydiary from '../../../assets/images/dailydiary.svg'
import dailydiaryactive from '../../../assets/images/dailydiaryactive.svg'
import welchIcon from '../../../assets/images/welchIcon.svg'



// Dropdown Close on Clicking outside : function

let useClickOutside = (handler) => {
  let domNode = useRef()

  useEffect(() => {
    if (!Cookies.get('tokenId')) {
      window.location.href = "/login";
    }
  }, [])


  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode?.current?.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", maybeHandler)

    return () => {
      document.removeEventListener("mousedown", maybeHandler)
    }
  }, [])
  return domNode;
}

function DashboardLayout() {

  let userInfo;
  let dashboard_obj;
  let dropdown_obj;
  let schoolLogo;

  const location = useLocation()

  function getWordBeforeDash() {
    let newStr = "";
    for (let i = 1; i < window.location.pathname.length; i++) {
      if (window.location.pathname[i] === "-" || window.location.pathname[i] === "/") {

        return newStr;
      }
      newStr += window.location.pathname[i];
    }
  }

  if (getWordBeforeDash() === "principal") {
    userInfo = {
      // user_name: "Ankita Sinha",
      // user_role: "Class Teacher",
      // image: rectangle,
      // bar: "",
      helpLink: '/principal-helpsupport'
    };
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/principal-dashboard",
      },
      {
        name: "Students",
        image: students,
        imageActive: studentsWhite,
        link: "/principal-student",
      },
      {
        name: "Teachers",
        image: events,
        imageActive: eventsWhite,
        link: "/principal-teacher",
      },
      {
        name: "Infirmary",
        image: infirmary,
        imageActive: infirmaryWhite,
        link: "/principal-infirmary",
      },

      // {
      //   name: "Counsellors",
      //   image: events,
      //   imageActive: eventsWhite,
      //   link: "",
      // },

      // {
      //   name: "Events",
      //   image: calender,
      //   imageActive: calWhite,
      //   link: "/principal-calender",
      // },
      // {
      //   name: "Report/Analytics",
      //   image: reportanalytics,
      //   imageActive: eventsWhite,
      //   link: "/principal-report",
      // },
    ];
    schoolLogo = {
      school_logo: schoollogo,
      school_name: "School Name ",
    };
  } else if (getWordBeforeDash() === "admin") {
    userInfo = {
      // user_name: "Ankita Sinha",
      // user_role: "Class Teacher",
      // image: rectangle,
      // bar: "",
      helpLink: '/admin-helpsupport'
    };
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/admin-dashboard",
      },
      {
        name: "Teachers",
        image: events,
        imageActive: eventsWhite,
        link: "/admin-teacher",
      },
      {
        name: "Infirmary",
        image: infirmary,
        imageActive: infirmaryWhite,
        link: "/admin-infirmary",
      },
      // {
      //   name: "Counsellor",
      //   image: performance,
      //   imageActive: performanceWhite,
      //   link: "/admin-counsellor",
      // },
      // {
      //   name: "Calendar",
      //   image: calender,
      //   imageActive: calenderWhite,
      //   link: "",
      // },
      {
        name: "Student",
        image: students,
        imageActive: studentsWhite,
        link: "/admin-student",
      },
      {
        name: "Report/Analytics",
        image: reportanalytics,
        imageActive: eventsWhite,
        link: "",
      },
      { name: "Settings", image: settings, imageActive: settingswhite, link: "" },
    ];
    schoolLogo = {
      school_logo: schoollogo,
      school_name: "School Name ",
    };
  } else if (location.pathname.includes("ops") && location.pathname.includes("nurse")) {
    userInfo = {
      // user_name: "Ankita Sinha",
      // user_role: "Class Teacher",
      // image: rectangle,
      // bar: "",
      helpLink: '/ops-helpsupport'
    };
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/ops-nurse/dashboard",
      },
    ]
  } else if (getWordBeforeDash() === "ops") {
    userInfo = {
      // user_name: "Ankita Sinha",
      // user_role: "Class Teacher",
      // image: rectangle,
      // bar: "",
      helpLink: '/ops-manager/helpsupport'
    };
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/ops-manager/dashboard",
      },

      // {
      //   name: "Event mapping",
      //   image: device,
      //   imageActive: deviceWhite,
      //   link: "/superadmin-events",
      // },
      , {
        name: "Re-exam",
        image: device,
        imageActive: deviceWhite,
        link: "/ops-manager/retake"
      },
      {
        name: "School Calendar",
        image: calender,
        imageActive: calWhite,
        link: "/ops-manager-calender",
      },
      {
        name: "Welch Allyn Data",
        image: welchIcon,
        imageActive: welchIcon,
        link: "/ops-manager/welch",
      },
      {
        name: "ENT Data",
        image: welchIcon,
        imageActive: welchIcon,
        link: "/ops-manager/ent-upload",
      },
      // { name: "Settings", image: settings, imageActive: settingswhite, link: "" },
    ];
    schoolLogo = {
      school_logo: "",
      school_name: "",
    };

  }
  else if (getWordBeforeDash() === "superadmin") {
    userInfo = {
      // user_name: "Ankita Sinha",
      // user_role: "Class Teacher",
      // image: rectangle,
      // bar: "",
      helpLink: '/superadmin-helpsupport'
    };
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/superadmin-dashboard",
      },

      {
        name: "School",
        image: school,
        imageActive: schoolWhite,
        link: "/superadmin-school",
      },
      // {
      //   name: "School Calendar",
      //   image: calender,
      //   imageActive: calenderWhite,
      //   link: "/superadmin-calendar",
      // },
      {
        name: "Clinic",
        image: clinic,
        imageActive: clinicWhite,
        link: "/superadmin-clinic",
      },
      // {
      //   name: "Patients",
      //   image: patient,
      //   imageActive: patientWhite,
      //   link: "/superadmin-patient",
      // },
      {
        name: "Users",
        image: students,
        imageActive: studentsWhite,
        link: "/superadmin-users/users",
      },
      // {
      //   name: "Event mapping",
      //   image: device,
      //   imageActive: deviceWhite,
      //   link: "/superadmin-events",
      // },

      // {
      //   name: "Assign Screening",
      //   image: stethoscope,
      //   imageActive: stethoscope,
      //   link: "/superadmin/assign",
      // },
      {
        name: "Validation Progress",
        image: stethoscope,
        imageActive: stethoscope,
        link: "/superadmin/validation/progress",
      },
      {
        name: "Report/Analytics",
        image: analytics,
        imageActive: analytics,
        link: "/superadmin/analytics",
      },
      {
        name: "Missing Data",
        image: reportanalytics,
        imageActive: eventsWhite,
        link: "/superadmin/missing-data",
      },
      {
        name: "Skids Analytics",
        image: analytics,
        imageActive: analytics,
        link: "/superadmin/skids-analytics",
      },
      // { name: "Settings", image: settings, imageActive: settingswhite, link: "" },
    ];

    schoolLogo = {
      school_logo: "",
      school_name: "",
    };
  }
  else if (getWordBeforeDash() === "support") {
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/support-dashboard",
      }
    ]
  }
  else if (getWordBeforeDash() === "writer") {
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/writer-dashboard",
      }
    ]
  }
  else if (getWordBeforeDash() === "partner") {
    userInfo = {
      // user_name: "Ankita Sinha",
      // user_role: "Class Teacher",
      // image: rectangle,
      // bar: "",
      helpLink: '/partner-helpsupport'
    };
    dashboard_obj = [
      // {
      //   name: "Dashboard",
      //   image: dashboard,
      //   imageActive: dashboardWhite,
      //   link: "/partner-dashboard",
      // },

      {
        name: "School",
        image: school,
        imageActive: schoolWhite,
        link: "/partner-school",
      },
      // {
      //   name: "School Calendar",
      //   image: calender,
      //   imageActive: calenderWhite,
      //   link: "/superadmin-calendar",
      // },
      // {
      //   name: "Clinic",
      //   image: clinic,
      //   imageActive: clinicWhite,
      //   link: "/superadmin-clinic",
      // },
      // {
      //   name: "Patients",
      //   image: patient,
      //   imageActive: patientWhite,
      //   link: "/superadmin-patient",
      // },
      // {
      //   name: "Users",
      //   image: students,
      //   imageActive: studentsWhite,
      //   link: "/partner-users/users",
      // },
      // {
      //   name: "Event mapping",
      //   image: device,
      //   imageActive: deviceWhite,
      //   link: "/superadmin-events",
      // },

      // {
      //   name: "Assign Screening",
      //   image: stethoscope,
      //   imageActive: stethoscope,
      //   link: "/superadmin/assign",
      // },
      // {
      //   name: "Validation Progress",
      //   image: stethoscope,
      //   imageActive: stethoscope,
      //   link: "/superadmin/validation/progress",
      // },
      // {
      //   name: "Report/Analytics",
      //   image: reportanalytics,
      //   imageActive: eventsWhite,
      //   link: "/superadmin/analytics",
      // },
      // { name: "Settings", image: settings, imageActive: settingswhite, link: "" },
    ];

    schoolLogo = {
      school_logo: "",
      school_name: "",
    };
  }

  else if (getWordBeforeDash() === 'teacher') {
    userInfo = {
      user_name: "Ankita Sinha",
      user_role: "Class Teacher",
      image: rectangle,
      bar: "",
      helpLink: '/teacher-helpsupport'
    };

    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/teacher-dashboard",
      },
      {
        name: "Students",
        image: students,
        imageActive: studentsWhite,
        link: "/teacher-student",
      },

      {
        name: "Report/Analytics",
        image: reportanalytics,
        imageActive: eventsWhite,
        link: "",
      },
      {
        name: "Settings",
        image: settings,
        imageActive: settingswhite,
        link: "",
      },
    ];

    schoolLogo = {
      school_logo: schoollogo,
      school_name: "School Name",
    };
  } else if (getWordBeforeDash() === 'doctor') {
    userInfo = {
      helpLink: '/doctor-helpsupport'
    };
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/doctor-dashboard",
      },

      {
        name: "Patients",
        image: device,
        imageActive: deviceWhite,
        link: "",
      },
      {
        name: "Report/Analytics",
        image: reportanalytics,
        imageActive: eventsWhite,
        link: "",
      },
      {
        name: "Settings",
        image: settings,
        imageActive: settingswhite,
        link: ""
      },
    ];
    schoolLogo = {
      school_logo: "",
      school_name: "",
    };
  } else if (getWordBeforeDash() === 'infirmary') {
    userInfo = {
      // user_name: "",
      // user_role: "",
      // image: rectangle,
      // bar: "",
      helpLink: '/infirmary-helpsupport'
    };

    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/infirmary-dashboard",
      },
      {
        name: "Daily Diary",
        image: dailydiary,
        imageActive: dailydiaryactive,
        link: "/infirmary/nurse/student",
      },

      {
        name: "Report/Analytics",
        image: reportanalytics,
        imageActive: eventsWhite,
        link: "",
      },
      {
        name: "Settings",
        image: settings,
        imageActive: settingswhite,
        link: "",
      },
    ];

    schoolLogo = {
      school_logo: schoollogo,
      school_name: "School Name",
    };
  } else if (getWordBeforeDash() === "behavioral") {
    dashboard_obj = [
      {
        name: "Dashboard",
        image: dashboard,
        imageActive: dashboardWhite,
        link: "/behavioral-doctor/dashboard",
      },
      // {
      //   name: "Assessments",
      //   image: reportanalytics,
      //   imageActive: eventsWhite,
      //   link: "/behavioral-doctor/dashboard/adhd",
      // },
      {
        name: "Appointments",
        image: school,
        imageActive: schoolWhite,
        link: "/behavioral-doctor/dashboard/appointments",
      },
      {
        name: "Patients",
        image: device,
        imageActive: deviceWhite,
        link: "/behavioral-doctor/dashboard/patients",
      },
    ]
  }



  const [notificationOpen, setNotificationOpen] = useState(false);
  const [open, setOpen] = useState(false);

  // const dropdown_obj = [
  //   { label: "My Profile", link: "/dashboard" },
  //   { label: "School Information", link: "/school-info" },
  //   { label: "Logout", link: "/login" },
  // ];

  const handleNotification = () => {
    setNotificationOpen(!notificationOpen);
  };


  // let domNode = useClickOutside(() => {
  //   setOpen(false)
  //   setNotificationOpen(false)
  // })


  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleClick = (e) => {
    setOpen(!open)
  }

  return (
    <div className={classes.sideBarContainer}>
      <div className={classes.logoDiv}>
        <img src={Logo} />
        <Typography style={{ color: "white", fontSize: 16, marginLeft: 20 }}>Skids Health</Typography>
      </div>
      <div className={classes.list_container}>
        <List component="nav" aria-label="main mailbox folders" id="dashboardNavSection">
          {dashboard_obj?.map((x, i) => {
            if (x.name === "Users") {
              return (
                <>
                  <ListItemButton onClick={handleClick} className={selectedIndex === i ? classes.navItemSelected : classes.navItemNotSelected} >
                    <div className={classes.sideBarItemDiv}>
                      <ListItemIcon>
                        <img src={x?.imageActive} />
                      </ListItemIcon>
                      <span style={{ color: "white", marginRight: 20 }}>{x?.name}</span>
                      {open ? <ExpandLessIcon style={{ fill: "white" }} /> : <ExpandMoreIcon style={{ fill: "white" }} />}
                    </div>
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ marginLeft: 56 }}>
                      <ListItemButton
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(event, i)}
                        className={location.pathname.includes("/users/internal") ? classes.navItemSelected : classes.navItemNotSelected}
                        key={i}
                      >
                        <Link to={location.pathname.includes('partner') ? "/partner/users/internal" : "/superadmin/users/internal"} className={classes.link}>
                          <div className={classes.sideBarItemDiv}>
                            <span style={{ color: "white" }}>Internal Users</span>
                          </div>
                        </Link>
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(event, i)}
                        className={location.pathname.includes("/users/parents") ? classes.navItemSelected : classes.navItemNotSelected}
                        key={i}
                      >
                        <Link to={location.pathname.includes('partner') ? "/partner/users/parents" : "/superadmin/users/parents"} className={classes.link}>
                          <div className={classes.sideBarItemDiv}>
                            <span style={{ color: "white" }}>Parents</span>
                          </div>
                        </Link>
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(event, i)}
                        className={location.pathname.includes("/users/schoolstaff") ? classes.navItemSAddelected : classes.navItemNotSelected}
                        key={i}
                      >
                        <Link to={location.pathname.includes('partner') ? "/partner/users/schoolstaff" : "/superadmin/users/schoolstaff"} className={classes.link}>
                          <div className={classes.sideBarItemDiv}>
                            <span style={{ color: "white" }}>School Staff</span>
                          </div>
                        </Link>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </>
              )
            }
            if (x.name === "Re-exam") {
              return (
                <>
                  <ListItemButton onClick={handleClick} className={selectedIndex === i ? classes.navItemSelected : classes.navItemNotSelected} >
                    <div className={classes.sideBarItemDiv}>
                      <ListItemIcon>
                        <img src={x?.imageActive} />
                      </ListItemIcon>
                      <span style={{ color: "white", marginRight: 20 }}>{x?.name}</span>
                      {open ? <ExpandLessIcon style={{ fill: "white" }} /> : <ExpandMoreIcon style={{ fill: "white" }} />}
                    </div>
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={{ marginLeft: 56 }}>
                      <ListItemButton
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(event, i)}
                        className={location.pathname === "/ops-manager/re-exam/absent" ? classes.navItemSelected : classes.navItemNotSelected}
                        key={i}
                      >
                        <Link to={"/ops-manager/re-exam/absent"} className={classes.link}>
                          <div className={classes.sideBarItemDiv}>
                            <span style={{ color: "white" }}>Absent Students</span>
                          </div>
                        </Link>
                      </ListItemButton>
                      <ListItemButton
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(event, i)}
                        className={location.pathname === "/ops-manager/re-exam/retake" ? classes.navItemSelected : classes.navItemNotSelected}
                        key={i}
                      >
                        <Link to={"/ops-manager/re-exam/retake"} className={classes.link}>
                          <div className={classes.sideBarItemDiv}>
                            <span style={{ color: "white" }}>Re-exam Students</span>
                          </div>
                        </Link>
                      </ListItemButton>
                    </List>
                  </Collapse>
                </>
              )
            }
            return (
              <ListItemButton
                selected={selectedIndex === i}
                onClick={(event) => handleListItemClick(event, i)}
                className={[location.pathname === x?.link ? classes.navItemSelected : classes.navItemNotSelected, 'link']}
                key={i}
              >
                <Link to={x?.link} state={x?.name === "Students" ? { ...location.state, breadCrumbs: ["Students"] } : {}} className={classes.link}>
                  <div className={classes.sideBarItemDiv}>
                    <ListItemIcon>
                      <img src={x?.imageActive} />
                    </ListItemIcon>
                    <span style={{ color: "white" }}>{x?.name}</span>
                  </div>
                </Link>
              </ListItemButton>
            )
          })}
        </List>
        {/* <Divider /> */}
        <div className={classes.dashboard_footer_container}>
          <div className={classes.dashboard_footer_container_inner}>
            <img src={needhelp} />
            <div>
              <p>Need Help?</p>
            </div>
            <Link to="#" className={classes.footer_link}>
              Check our documentation
            </Link>
          </div>
          <div className={classes.helpsupport_container}>
            <div>
              <img src={helpsupport} />
            </div>
            <div>
              <Link to={userInfo?.helpLink} className={classes.helpsupport_link}>Help and Support</Link></div>
          </div>
          <div className={classes.app_version}>App version 1.0.0.1</div>
        </div>

      </div>
    </div>
  )
}

export default DashboardLayout;
