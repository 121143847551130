import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, } from "react-router-dom";
import AuthRoutes from './AuthRoutes';
import Splash from "../components/SkidsSplash/Splash";
import RegistrationPage from "../components/RegistrationPage/RegistrationPage";
import Login from "../components/Login/Login";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ResetPassword from "../components/ResetPassword/ResetPassword";
import OTP from "../components/common/OTP/OTP";
import PasswordUpdateSucess from "../components/PasswordUpdateSucess/PasswordUpdateSucess";
import Dashboard from "../components/Dashboard/Dashboard";
import TermsAndConditions from "../components/common/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../components/common/PrivacyPolicies/PrivacyPolicy";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import SchoolInfo from "../components/SchoolInfo/SchoolInfo";
import EditSchoolDetails from "../components/EditSchoolDetails/EditSchoolDetails";
import Student from "../components/common/StudentTable/Student";
import Teacher from "../components/common/TeacherTable/Teacher";
import TeacherDashboard from '../components/TeacherFlow/TeacherDashboard/TeacherDashboard'
import TeacherStudent from '../components/common/StudentTable/Student'
import TeacherInfirmary from '../components/TeacherFlow/TeacherInfirmary/TeacherInfirmary'
import PrincipalDashboard from '../components/PrincipalFlow/PrincipalDashboard/PrincipalDashboard'
import PrincipalStudent from "../components/PrincipalFlow/PrincipalStudent/PrincipalStudent";
import PrincipalTeacher from '../components/PrincipalFlow/PrincipalTeacher/PrincipalTeacher'
import PrincipalInfirmary from "../components/PrincipalFlow/PrincipalInfirmary/PrincipalInfirmary";
import HelpSupport from '../components/HelpSupport/HelpSupport'
import AdminDashboard from "../components/ITAdminFlow/AdminDashboard/AdminDashboard";
import AdminTeacher from "../components/ITAdminFlow/AdminTeacher/AdminTeacher";
import AdminStudent from "../components/ITAdminFlow/AdminStudent/AdminStudent";
import AdminInfirmary from "../components/ITAdminFlow/AdminInfirmary/AdminInfirmary";
import AdminSchoolInfo from "../components/ITAdminFlow/AdminSchoolInfo/AdminSchoolInfo";
import TeacherSchoolInfo from "../components/TeacherFlow/TeacherSchoolInfo/TeacherSchoolInfo";
import PrincipalSchoolInfo from "../components/PrincipalFlow/PrincipalSchoolInfo/PrincipalSchoolInfo";
import AdminEdit from "../components/ITAdminFlow/AdminEdit/AdminEdit";
import TeacherEdit from "../components/TeacherFlow/TeacherEdit/TeacherEdit";
import PrincipalEdit from "../components/PrincipalFlow/principalEdit/PrincipalEdit";
import SuperAdminDashboard from "../components/SuperAdmin/SuperAdminDashboard/SuperAdminDashboard";
import PrincipalHelpSupport from '../components/PrincipalFlow/HelpAndSupport/HelpAndSupport'
import TeacherHelpSupport from '../components/TeacherFlow/HelpAndSupport/HelpAndSupport'
import AdminHelpSupport from "../components/ITAdminFlow/HelpAndSupport/HelpAndSupport";
import SuperAdminSchool from "../components/SuperAdmin/SuperAdminSchools/SuperAdminSchool";
import SuperAdminEditSchool from '../components/SuperAdmin/SuperAdminEdit/SuperAdminEdit'
import SuperAdminClinic from '../components/SuperAdmin/SuperAdminClinic/SuperAdminClinic'
import SuperAdminAddOrganization from '../components/common/AddOrganization/AddOrganization'
import StudentScreening from '../components/common/StudentName/StudentName'
import { IndividualTable } from '../components/common/IndividualTable/IndividualTable';
import GuestRoutes from './GuestRoutes';
import { useSelector } from 'react-redux';
import Navbar from '../components/common/Navbar/Navbar';
import AddIndividual from '../components/common/AddIndividual/AddIndividual';
import AddEditIndividual from '../components/AddEditIndividual/AddEditIndividual';
import EditOrgSchool from '../components/common/EditOrganization/EditOrganization';
import AddEditUser from '../components/AddEditIndividual/AddEditUser'
import EditUser from '../components/common/EditUser/EditUser';
import SuperAdminUser from '../components/SuperAdmin/SuperAdminUser/SuperAdminUser'
import EditIndividual from '../components/EditIndividual/EditIndividual';
import EventDeviceTable from '../components/EventDevice/EventTable'
import SuperAdminCalendar from '../components/SuperAdmin/SuperAdminCalendar/SuperAdminCalendar'
import OpsIndividualScreening from '../components/OpsTeam/OpsIndividualScreening/IndividualScreening'
import MyProfile from '../components/common/MyProfile/MyProfile';
import PrincipalCalendar from '../components/PrincipalFlow/PrincipalCalendar/PrincipalCalendar'
import PrincipalRoutes from './PrincipalRoutes';
import SuperAdminRoutes from './SuperAdminRoutes';
import TeacherRoutes from './TeacherRoutes';
import AdminRoutes from './AdminRoutes';
import DashboardLayout from '../components/common/DashboardLayout/DashboardLayout';
import BulkUpload from '../components/BulkUpload/BulkUpload';
import SuperAdminClinicTable from '../components/SuperAdmin/SuperAdminClinic/SuperAdminClinic';
import Studentscreening from '../components/common/StudentName/StudentName';
import TeacherInfirmaryPage from '../components/TeacherFlow/TeacherInfirmary/TeacherInfirmary';
import TeacherDashboardPage from '../components/TeacherFlow/TeacherDashboard/TeacherDashboard';
import HelpSupportPage from '../components/HelpSupport/HelpSupport';
import OpsRoutes from './OpsRoutes';
import Assign from '../components/Assign/Assign';
import { OpsNurseDashboard } from '../components/OpsTeam/OpsTeamDashboard/OpsNurseDashboard';
import OpsManagerTeamDashboard from '../components/OpsTeam/OpsTeamDashboard/OpsManager/OpsManagerTable';
import OpsManagerStudent from '../components/OpsTeam/OpsTeamDashboard/OpsManager/OpsStudent';
import InternalUser from '../components/SuperAdmin/SuperAdminUser/InternalUsers/InternalUser'
import Parents from '../components/SuperAdmin/SuperAdminUser/Parents/Parents'
import SchoolStaff from '../components/SuperAdmin/SuperAdminUser/SchoolStaff/SchoolStaff';
import { NurseHealthHistory } from '../components/InfirmaryFlow/NurseHealthHistory';
import { NurseAddLog } from '../components/InfirmaryFlow/NurseAddLog';
import { NursePreScreening } from '../components/InfirmaryFlow/NursePreScreening';
import NurseStudent from '../components/InfirmaryFlow/NurseStudents/NurseStudents';
import DoctorDashboard from '../components/DoctorValidation/DoctorDashboard';
import HealthReport from '../components/HealthReport/HealthReport';
import OpsCalendar from '../components/OpsCalendar/OpsCalendar';
import { OpsManagerDashboard } from '../components/OpsTeam/OpsTeamDashboard/OpsManager/OpsManagerDashboard';
import OtherProfile from '../components/common/OtherProfile/OtherProfile';
import ManageScreening from '../components/ManageScreening/ManageScreening';
import SchoolStudent from '../components/common/SchoolTable/SchoolStudent/SchoolStudent';
import DoctorRoutes from './DoctorRoutes';
import SchoolInfirmaryRoutes from './SchoolInfirmaryRoutes';
import DoctorValidation from '../components/DoctorFlow/DoctorValidation';
import ValidationProgress from '../components/ValidationProgress/Validation';
import BehavioralValidation from '../components/ManageScreening/BehavioralScreening';
import OpsManagerRoutes from './OpsManagerRoutes';
import OpsNurseRoutes from './OpsNurseRoutes';
import Main from '../components/BehavioralReport/MainReport/Main';
import BehavioralDoctorDashboard from '../components/BehavioralDoctorDashboard/BehavioralDoctorDashboard';
import AssessmentTable from '../components/BehavioralDoctorDashboard/Assessments/AssessmentTable';
import AppointmentsTable from '../components/BehavioralDoctorDashboard/Appointments/AppointmentsTable';
import PatientsTable from '../components/BehavioralDoctorDashboard/Patients/PatientsTable';
import HealthHistory from '../components/BehavioralDoctorDashboard/Patients/healthHistory/HealthHistory';
import AddLog from '../components/BehavioralDoctorDashboard/AddLog/AddLog';
import Faq from '../components/Faq/Faq';
import ClinicInfo from '../components/ClinicInfo/ClinicInfo';
import AddClinic from '../components/AddClinic/AddClinic';
import EditClinic from '../components/EditClinic/EditClinic';
import AnalyticsPage from '../components/AnalyticsPage/AnalyticsPage';
import BulkUploadErrorTablePage from '../components/BulkUpload/BulkUploadErrorTablePage';
import DentalDoctorStudents from '../components/DentalDoctor/DentalDoctorStudents';
import DentalDoctorValidation from '../components/DoctorFlow/DentalDoctorValidation';
import BehavioralProfile from '../components/BehavioralDoctorDashboard/Profile/BehavioralProfile';
import BulkReportDownload from '../components/BulkReportDownload/BulkReportDownload';
import D2cMain from '../components/D2cBehavioralReport/MainReport/D2cMain';
import WelchAllynTable from '../components/OpsTeam/OpsTeamDashboard/WelchAllyn/WelchAllynTable';
import WelchSchool from '../components/OpsTeam/OpsTeamDashboard/WelchScholl/WelchSchool';
import RetakeTable from '../components/OpsTeam/OpsTeamDashboard/RetakeTable/RetakeTable';
import AbsentTable from '../components/OpsTeam/OpsTeamDashboard/AbsentTable/AbsentTable';
import OpsEvent from '../components/OpsTeam/OpsTeamDashboard/OpsEvent/OpsEvent';
import BmiChart2point0 from '../assets/images/BmiChart/BmiChart2.0';
import PhydoctorStudents from '../components/PhyDoctor/PhydoctorStudents';
import PartnerRoutes from './PartnerRoutes';
import PartnerSchools from '../components/PartnerFlow/PartnerSchools/PartnerSchools';
import SupportRoutes from './SupportRoutes';
import TicketsPage from '../components/HelpSupport/TicketsPage';
import TicketDetail from '../components/HelpSupport/TicketDetail';
import AssignDoctor from '../components/Assign/AssignDoctor';
import MissingDataAnalytics from '../components/AnalyticsPage/MissingDataAnalytics';
import EntUpload from '../components/OpsTeam/OpsTeamDashboard/EntUpload/EntUpload';
import WriterRoutes from './WriterRoutes';
import WriterDashboard from '../components/ContentWriter/WriterDashboard';
import CreateBlog from '../components/ContentWriter/CreateBlog';
import BlogDetail from '../components/ContentWriter/BlogDetail';
import SkidsAnalytics from '../components/AnalyticsPage/SkidsAnalytics';


function CustomRoutes() {

  const loggedIn = useSelector((state => state.authReducer.loggedIn))
  const user = useSelector((state) => state.userReducer.currentUser)

  return (
    <Routes>
      <Route path="/" element={<Navigate to={'login'} />} />
      <Route path="login" element={<GuestRoutes component={<Login />} />}></Route>
      <Route path="forgot-password" element={<GuestRoutes component={<ForgotPassword />} />}></Route>
      <Route path="registration" element={<GuestRoutes component={<RegistrationPage />} />} />
      <Route path="otp-verification/:verifyType" element={<GuestRoutes component={<OTP />} />} />
      <Route path="reset-password" element={<GuestRoutes component={<ResetPassword />} />} />
      <Route path="verified" element={<GuestRoutes component={<PasswordUpdateSucess />} />} />
      <Route path="/health-report" element={<GuestRoutes component={<HealthReport />} />} />
      <Route path="/behavioral-report/:type" element={<GuestRoutes component={<Main />} />} />


      <Route path="/admin-dashboard" element={<AdminRoutes component={<AdminDashboard />} />} />
      <Route path="admin-teacher" element={<AdminRoutes component={<AdminTeacher />} />} />
      <Route path="admin-student" element={<AdminRoutes component={<AdminStudent />} />} />
      <Route path="admin-counsellor" element={<AdminRoutes component={<></>} />} />
      <Route path="/admin-bulkupload" element={<AdminRoutes component={<BulkUpload />} />} />
      <Route path="admin-infirmary" element={<AdminRoutes component={<AdminInfirmary />} />} />
      <Route path="admin-helpsupport" element={<AdminRoutes component={<AdminHelpSupport />} />} />
      <Route path="admin/info/:entityName" element={<AdminRoutes component={<SchoolInfo />} />} />
      <Route path=":admin/table/:type" element={<AdminRoutes component={<IndividualTable />} />} />
      <Route path="admin-infirmary/individual/user" element={<AdminRoutes component={<AddEditUser />} />} />
      <Route path='/admin-teacher/individual/user' element={<AdminRoutes component={<AddEditUser />} />} />
      <Route path='/admin-student/individual/student' element={<AdminRoutes component={<AddEditIndividual />} />} />
      <Route path='admin-profile' element={<AdminRoutes component={<MyProfile />} />} />
      <Route path='admin-schoolinfo' element={<AdminRoutes component={<SchoolInfo />} />} />
      <Route path='/admin/user/profile' element={<AdminRoutes component={<OtherProfile />} />} />

      <Route path='/partner-school' element={<PartnerRoutes component={<SuperAdminSchool />} />} />
      <Route path="/partner/individual/edit/student" element={<PartnerRoutes component={<EditIndividual />} />} />
      <Route path="/partner-school/school" element={<PartnerRoutes component={<AddIndividual />} />} />
      <Route path="/partner-school/individual/:person" element={<PartnerRoutes component={<AddEditIndividual />} />} />
      <Route path="partner-school/info/:entityName" element={<PartnerRoutes component={<SchoolInfo />} />} />
      <Route path="partner/:superadmin/:entity" element={<PartnerRoutes component={< SuperAdminAddOrganization />} />} />
      <Route path="partner/:superadmin/edit/:entity" element={<PartnerRoutes component={<EditOrgSchool />} />} />
      <Route path="/partner-school/table/:type" element={<PartnerRoutes component={<IndividualTable />} />} />
      <Route path="/partner-screening" element={<PartnerRoutes component={<Studentscreening />} />} />
      <Route path="/partner-events" element={<PartnerRoutes component={<EventDeviceTable />} />} />
      <Route path='/partner-download-reports' element={<PartnerRoutes component={<BulkReportDownload />} />} />
      {/* <Route path='/partner/users/internal' element={<PartnerRoutes component={<InternalUser />} />} />
      <Route path='/partner/users/parents' element={<PartnerRoutes component={<Parents />} />} />
      <Route path='/partner/users/schoolstaff' element={<PartnerRoutes component={<SchoolStaff />} />} /> */}
      <Route path="/partner/partner-school/info/:entityName" element={<PartnerRoutes component={<SchoolInfo />} />} />
      <Route path='/partner/bulk-upload-error' element={<PartnerRoutes component={<BulkUploadErrorTablePage />} />} />

      <Route path="/superadmin-dashboard" element={<SuperAdminRoutes component={<SuperAdminDashboard />} />} />
      <Route path="/superadmin-school" element={<SuperAdminRoutes component={< SuperAdminSchool />} />} />
      <Route path="/superadmin-clinic" element={<SuperAdminRoutes component={< SuperAdminClinicTable />} />} />
      <Route path="superadmin-school/info/:entityName" element={<SuperAdminRoutes component={<SchoolInfo />} />} />
      <Route path="superadmin-clinic/info/:entityName" element={<SuperAdminRoutes component={<ClinicInfo />} />} />
      <Route path="/superadmin-school/table/:type" element={<SuperAdminRoutes component={<IndividualTable />} />} />
      <Route path="/superadmin-clinic/table/:type" element={<SuperAdminRoutes component={<IndividualTable />} />} />
      <Route path="/superadmin/individual/edit/:person" element={<SuperAdminRoutes component={<AddEditIndividual />} />} />
      <Route path="/superadmin-school/individual/:person" element={<SuperAdminRoutes component={<AddEditIndividual />} />} />
      <Route path="/superadmin-users/individual/user" element={<SuperAdminRoutes component={<AddEditUser />} />} />
      <Route path="/superadmin-users/users" element={<SuperAdminRoutes component={<SuperAdminUser />} />} />
      <Route path="/superadmin-events" element={<SuperAdminRoutes component={<EventDeviceTable />} />} />
      <Route path="/superadmin-calendar" element={<SuperAdminRoutes component={<SuperAdminCalendar />} />} />
      <Route path="/superadmin-screening" element={<SuperAdminRoutes component={<Studentscreening />} />} />
      <Route path="/superadmin/individual/edit/student" element={<SuperAdminRoutes component={<EditIndividual />} />} />
      <Route path="/superadmin-editschool" element={<SuperAdminRoutes component={< EditOrgSchool />} />} />
      <Route path="/superadmin-clinc/add-clinic" element={<SuperAdminRoutes component={<AddClinic />} />} />
      <Route path="/superadmin-clinc/edit-clinic" element={<SuperAdminRoutes component={<EditClinic />} />} />
      <Route path=":superadmin/:entity" element={<SuperAdminRoutes component={< SuperAdminAddOrganization />} />} />
      <Route path=":superadmin/edit/:entity" element={<SuperAdminRoutes component={<EditOrgSchool />} />} />
      <Route path="/superadmin-profile" element={<SuperAdminRoutes component={<MyProfile />} />} />
      <Route path="/superadmin-screening" element={<SuperAdminRoutes component={<Studentscreening />} />} />
      <Route path='/superadmin-helpsupport' element={<SuperAdminRoutes component={<HelpSupportPage />} />} />
      {/* <Route path ='/superadmin/assign' element = {<SuperAdminRoutes component = {<Assign/>} />}/> */}
      <Route path='/superadmin/users/internal' element={<SuperAdminRoutes component={<InternalUser />} />} />
      <Route path='/superadmin/users/parents' element={<SuperAdminRoutes component={<Parents />} />} />
      <Route path='/superadmin/users/schoolstaff' element={<SuperAdminRoutes component={<SchoolStaff />} />} />
      <Route path='/superadmin/school/student/d2clogs' element={<SuperAdminRoutes component={<SchoolStudent />} />} />
      {/* <Route path='/superadmin/validation/progress' element={<SuperAdminRoutes component={<ValidationProgress />} />} /> */}
      <Route path='/superadmin/validation/progress' element={<SuperAdminRoutes component={<Assign />} />} />
      <Route path='/superadmin/validation/assign' element={<SuperAdminRoutes component={<AssignDoctor />} />} />
      <Route path='/superadmin/manage/screening/physical' element={<SuperAdminRoutes component={<ManageScreening />} />} />
      <Route path='/superadmin/manage/screening/behavioral' element={<SuperAdminRoutes component={<BehavioralValidation />} />} />
      <Route path='/superadmin/user/profile' element={<SuperAdminRoutes component={<OtherProfile />} />} />
      <Route path='/superadmin-dashboard/faq' element={<SuperAdminRoutes component={<Faq />} />} />
      <Route path="/superadmin/analytics" element={<SuperAdminRoutes component={<AnalyticsPage />} />} />
      <Route path="/superadmin/skids-analytics" element={<SuperAdminRoutes component={<SkidsAnalytics />} />} />
      <Route path="/superadmin/missing-data" element={<SuperAdminRoutes component={<MissingDataAnalytics />} />} />
      <Route path='/superadmin/bulk-upload-error' element={<SuperAdminRoutes component={<BulkUploadErrorTablePage />} />} />
      <Route path='/superadmin-download-reports' element={<SuperAdminRoutes component={<BulkReportDownload />} />} />
      <Route path='/secachart' element={<SuperAdminRoutes component={<BmiChart2point0 />} />} />


      <Route path="/ops-manager/dashboard" element={<OpsManagerRoutes component={<OpsManagerDashboard />} />} />
      <Route path="/ops-manager/dashboard/student" element={<OpsManagerRoutes component={<OpsManagerStudent />} />} />
      <Route path='/ops-manager/dashboard/:schoolName/mapped-event' element={<OpsManagerRoutes component={<OpsEvent />} />} />
      <Route path="ops-manager/:screeningId" element={<OpsManagerRoutes component={<OpsIndividualScreening />} />} />
      <Route path="/ops-manager-calender" element={<OpsManagerRoutes component={<OpsCalendar />} />} />
      <Route path='/ops-manager-helpsupport' element={<OpsManagerRoutes component={<HelpSupportPage />} />} />
      <Route path='/ops-manager-profile' element={<OpsManagerRoutes component={<OtherProfile />} />} />
      <Route path='/ops-manager/re-exam/retake' element={<OpsManagerRoutes component={<AbsentTable type='reTake' />} />} />
      <Route path='/ops-manager/re-exam/absent' element={<OpsManagerRoutes component={<AbsentTable type='absent' />} />} />
      <Route path='/ops-manager/welch' element={<OpsManagerRoutes component={<WelchAllynTable />} />} />
      <Route path='/ops-manager/ent-upload' element={<OpsManagerRoutes component={<EntUpload />} />} />
      <Route path='/ops-manager/welch/:schoolName' element={<OpsManagerRoutes component={<WelchSchool />} />} />
      <Route path='/ops-manager/helpsupport' element={<OpsManagerRoutes component={<HelpSupportPage />} />} />


      <Route path="/ops-nurse/dashboard" element={<OpsNurseRoutes component={<OpsNurseDashboard />} />} />
      <Route path="ops-nurse/:screeningId" element={<OpsNurseRoutes component={<OpsIndividualScreening />} />} />
      <Route path='/ops-nurse-profile' element={<OpsNurseRoutes component={<OtherProfile />} />} />

      {/* Infirmary  & Doctor Routes added to Ops for testing */}
      <Route path="infirmary/nurse/student/health" element={<SchoolInfirmaryRoutes component={<NurseHealthHistory />} />} />
      <Route path="infirmary/nurse/student/addlog" element={<SchoolInfirmaryRoutes component={<NurseAddLog />} />} />
      <Route path="infirmary-dashboard" element={<SchoolInfirmaryRoutes component={<NursePreScreening />} />} />
      <Route path="infirmary/nurse/student" element={<SchoolInfirmaryRoutes component={<NurseStudent />} />} />
      <Route path="infirmary/nurse/student/screening" element={<SchoolInfirmaryRoutes component={<StudentScreening />} />} />
      <Route path='/infirmary-helpsupport' element={<SchoolInfirmaryRoutes component={<HelpSupportPage />} />} />


      <Route path="doctor-dashboard" element={<DoctorRoutes component={<DoctorDashboard />} />} />
      <Route path='/doctor-school/:schoolId' element={<DoctorRoutes component={<DentalDoctorStudents />} />} />
      <Route path='/doctor-physchool/:schoolId' element={<DoctorRoutes component={<PhydoctorStudents />} />} />
      <Route path="doctor-dentalvalidation/:studentId" element={<DoctorRoutes component={<DentalDoctorValidation />} />} />
      <Route path="doctor-validation/:studentId" element={<DoctorRoutes component={<DoctorValidation />} />} />
      <Route path='/doctor-helpsupport' element={<DoctorRoutes component={<HelpSupportPage />} />} />


      <Route path='/behavioral-doctor/dashboard' element={<DoctorRoutes component={<BehavioralDoctorDashboard />} />} />
      <Route path='/behavioral-doctor/info/:entityName' element={<DoctorRoutes component={<BehavioralProfile />} />} />
      <Route path='/behavioral-doctor/dashboard/:assessments' element={<DoctorRoutes component={<AssessmentTable />} />} />
      <Route path='/behavioral-doctor/dashboard/appointments' element={<DoctorRoutes component={<AppointmentsTable />} />} />
      <Route path='/behavioral-doctor/dashboard/patients' element={<DoctorRoutes component={<PatientsTable />} />} />
      <Route path='/behavioral-doctor/dashboard/:typeName/healthHistory' element={<DoctorRoutes component={<HealthHistory />} />} />
      <Route path='/behavioral-doctor/dashboard/patients/:add' element={<DoctorRoutes component={<AddLog />} />} />
      <Route path="/d2c-report/:type" element={<GuestRoutes component={<D2cMain />} />} />


      <Route path="principal-student" element={<PrincipalRoutes component={<PrincipalStudent />} />} />
      <Route path="principal-calender" element={<PrincipalRoutes component={<PrincipalCalendar />} />} />
      <Route path="principal-teacher" element={<PrincipalRoutes component={<PrincipalTeacher />} />} />
      <Route path="principal-infirmary" element={<PrincipalRoutes component={<PrincipalInfirmary />} />} />
      <Route path="principal-schoolinfo" element={<PrincipalRoutes component={<PrincipalSchoolInfo />} />} />
      <Route path="principal-helpsupport" element={<PrincipalRoutes component={<PrincipalHelpSupport />} />} />
      <Route path="principal/info/:entityName" element={<PrincipalRoutes component={<SchoolInfo />} />} />
      <Route path="principal/table/:type" element={<PrincipalRoutes component={<IndividualTable />} />} />
      <Route path="principal/individual/:person" element={<PrincipalRoutes component={<EditIndividual />} />} />
      <Route path="principal/individual/:user/:type" element={<PrincipalRoutes component={<AddEditUser />} />} />
      <Route path="/principal-infirmary/individual/user" element={<PrincipalRoutes component={<AddEditUser />} />} />
      <Route path="principal/individual/edit/:user" element={<PrincipalRoutes component={<EditUser />} />} />
      <Route path="principal-dashboard" element={<PrincipalRoutes component={<PrincipalDashboard />} />} />
      <Route path="/principal-screening" element={<PrincipalRoutes component={<Studentscreening />} />} />
      <Route path="principal-teacher/profile" element={<PrincipalRoutes component={<OtherProfile />} />} />
      <Route path="/principle/profile" element={<PrincipalRoutes component={<MyProfile />} />} />
      <Route path='/principal-teacher/individual/user' element={<PrincipalRoutes component={<AddEditUser />} />} />
      <Route path="principal-student/individual/student" element={<PrincipalRoutes component={<AddEditIndividual />} />} />
      <Route path='/principal/user/profile' element={<PrincipalRoutes component={<OtherProfile />} />} />
      <Route path='/principal-profile' element={<PrincipalRoutes component={<OtherProfile />} />} />


      <Route path="teacher-dashboard" element={<TeacherRoutes component={<TeacherDashboardPage />} />} />
      <Route path="teacher-student" element={<TeacherRoutes component={<TeacherStudent />} />} />
      <Route path="teacher-student/individual/:person" element={<TeacherRoutes component={<AddEditIndividual />} />} />
      <Route path="teacher-screening" element={<TeacherRoutes component={<Studentscreening />} />} />
      <Route path="teacher/individual/:person" element={<TeacherRoutes component={<EditIndividual />} />} />
      <Route path="teacher-infirmary" element={<TeacherRoutes component={<TeacherInfirmaryPage />} />} />
      <Route path="teacher-helpsupport" element={<TeacherRoutes component={<TeacherHelpSupport />} />} />

      {/* Support Admin Routes */}
      <Route path="/support-dashboard" element={<SupportRoutes component={<TicketsPage />} />} />
      <Route path="/support-ticket/info/:id" element={<SupportRoutes component={<TicketDetail />} />} />
      <Route path="/support-ticket/edit/:id" element={<SupportRoutes component={<TicketDetail />} />} />

      {/* Content Writer Routes */}
      <Route path="/writer-dashboard" element={<WriterRoutes component={<WriterDashboard />} />} />
      <Route path="/writer/write" element={<WriterRoutes component={<CreateBlog />} />} />
      <Route path="/writer/info/:id" element={<WriterRoutes component={<BlogDetail />} />} />
      <Route path="/writer/edit/:id" element={<WriterRoutes component={<CreateBlog />} />} />

      <Route path='*' element={<PageNotFound />}></Route>

    </Routes>
  )
}

export default CustomRoutes;
