import { graphqlOperation } from "@aws-amplify/api-graphql/lib-esm/internals/InternalGraphQLAPI";
import { API } from "aws-amplify";
import Cookies from "js-cookie";

function getAnalytics(payload) {
    const token = Cookies.get('tokenId')
    const params = payload?.payload
    return API.graphql(graphqlOperation(
        `query MyQuery($issues: [AvailableAssessments]) {
            getSchoolAnalysis(schoolId: "${params?.schoolId}", class: "${params?.class}", section: "${params?.section}" issues: $issues)
          }`,
        {issues: params?.issues},
        token
    ))
}

function getAnalyticsData(payload) {
    const token = Cookies.get('tokenId')
    const params = payload;
    return API.graphql(graphqlOperation(
        `query MyQuery($state: [String], $city: [String], $schoolId: [String], $issues: [AvailableAssessments]) {
            getAnalytics(state: $state, city: $city, schoolId: $schoolId, issues: $issues)
          }`,
        {state: params?.state ? params?.state : null, city: params?.city ? params?.city : null, schoolId: params?.schoolId, issues: params?.issues},
        token
    ))
}


const analytics = {
    getAnalytics,
    getAnalyticsData
}

export default analytics;